document.addEventListener("DOMContentLoaded", function () {
	if (!PRODUCTION) console.log("DOMContentLoaded");

	// initVue();
	// handleHamburgers();
	// handleDropdowns();
	handleNavSlide();
	handleHamburgers();

	if (document.getElementById("homebanner")) {
		handleBannerAnimations();
	}
});

function handleBannerAnimations() {
	const homebanner = document.getElementById("homebanner");
	const preheader = homebanner.querySelector(".preheader");
	const bigTitle = homebanner.querySelector(".big-title");
	const para = homebanner.querySelector(".content");

	const animationCollection = [preheader, bigTitle, para];

	setTimeout(() => {
		animationCollection.forEach((item) => {
			item.classList.add("is-active");
		});
	}, 1000);
}

const navigation = document.getElementById("navigation");

function handleNavSlide() {
	const logoWrapper = document.getElementById("logo_wrapper");

	if (!logoWrapper || !navigation) return;

	logoWrapper.addEventListener("click", function () {
		if (!navigation.classList.contains("is-open")) {
			navigation.classList.add("is-open");
			return;
		}
		navigation.classList.remove("is-open");
	});
}

function handleHamburgers() {
	// Get all "navbar-burger" elements
	const logoWrapper = document.getElementById("logo_wrapper");
	const header = document.getElementById("top");
	const burger = logoWrapper.querySelector(".navbar-burger");
	const label = burger.querySelectorAll("p")[0];

	function handleClose(event) {
		if (!header.contains(event.target)) {
			deactivate();
			this.removeEventListener("click", handleClose);
		}
		console.log("listening");
	}

	const headerContainerWrap = document.getElementById("ajax-content-wrap");

	function deactivate() {
		burger.classList.remove("is-active");
		navigation.classList.remove("is-open");
		logoWrapper.classList.remove("is-active");
		label.innerHTML = "MENU";

		headerContainerWrap.style.transition = "padding-top .5s";
		headerContainerWrap.style.paddingTop = "0px";
	}

	function activate() {
		logoWrapper.classList.add("is-active");
		burger.classList.add("is-active");
		label.innerHTML = "CLOSE";

		if (window.innerWidth > 999) {
			const expandedNavHeight =
				document.getElementById("navigation").offsetHeight;

			headerContainerWrap.style.transition = "padding-top .5s";
			headerContainerWrap.style.paddingTop = expandedNavHeight + "px";
		}
	}

	logoWrapper.addEventListener("click", function () {
		// Get the target from the "data-target" attribute
		if (!burger.classList.contains("is-active")) {
			activate();
			document.addEventListener("click", handleClose);
		} else {
			deactivate();
		}
	});
}

$(".nectar-post-grid .nectar-post-grid-item").hover(
	function () {
		$(this).find(".meta-excerpt").fadeIn();
	},
	function () {
		$(this).find(".meta-excerpt").fadeOut();
	}
);
